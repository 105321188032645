@import url('https://fonts.googleapis.com/css2?family=Amarante&family=Poppins:wght@400;500;600;700;800&display=swap');


body {
  overflow-x: hidden;
  color: rgba(255, 242, 209, 1);
  /* background-color: #16072b; */
  font-family: Poppins;
}

.token-amount-value {
  background: transparent;
}

.token-amount-value:focus-visible{
  outline: none;
}

.open-currency-select-button-bg {
  background: transparent;
}

/* .gradient {
  border: 1px solid;
  border-image-source: linear-gradient(225deg, #F500FA -45.77%, #4100FA 107.31%);
  border-image-slice: fill;

  background: linear-gradient(225deg, #f500fa -45.77%, #4100fa 107.31%);
} */

.textShadowLg {
  text-shadow: 0px -4px 12px rgb(255 229 171 / 35%), 0px 4px 4px rgb(0 0 0 / 25%);
}

.inner-box-bg {
  box-shadow: 0px 4px 4px 0px rgb(7 0 14 / 25%);
  background: #1e0038;
}

.bg-cart {
  background: linear-gradient(360deg, rgba(30, 0, 56, 1) 0%, rgba(22, 0, 44, 1) 56.05%);
  /* background: linear-gradient(360deg, rgba(30, 0, 56, 0.45) 0%, rgba(22, 0, 44, 0.6) 56.05%); */
  border: 1px solid #a000fa;
  border-radius: 20px;
}

.max-btn {
  background: linear-gradient(225deg, #a839ff -45.77%, rgba(16, 0, 61, 0.5) 107.31%);
  border: 1px solid #b500fa;
  color: #fff2d1;
  text-shadow: 0px -4px 12px rgba(255, 229, 171, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  margin-left: 0.25rem;
}

.fifty-percent {
  margin-right: 0px;
}

span.bg-cart{
  position: fixed;
  top: 10rem;
  padding: 0;
  width: auto;
  margin-left: 5px;
  margin-right: 5px;
  right: 0;
}

span.bg-cart{
  position: fixed;
  top: 10rem;
  padding: 0;
  width: auto;
  margin-left: 5px;
  margin-right: 5px;
  right: 0;
}

/* .btn {
  background: linear-gradient(225deg, #a839ff -45.77%, rgba(16, 0, 61, 0.5) 107.31%);
  border: 1px solid #b500fa;
  color: white;

  border-radius: 15px;
} */

#open-settings-dialog-button {
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn {
  background-image: linear-gradient(225deg, #a839ff, rgba(16, 0, 61, 1) 107.31%);
  border: 1px solid rgba(148, 1, 251, 1);
  color: rgba(255, 242, 209, 1);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  border-radius: 16px;
}

.btn:hover {
  background-color: rgba(19, 54, 98, 0.44);
  color: #fff2d1 !important;
}

.btn:active {
  background-color: #601cba;
}

.btn:disabled {
  opacity: 50%;
  cursor: auto;
  box-shadow: none;
  background: linear-gradient(225deg, #a839ff -45.77%, rgba(16, 0, 61, 0.5) 107.31%);
  border: 1px solid #b500fa;
}

/* 
#connect-wallet {
  border: none;
  background: linear-gradient(225deg, #460877 -45.77%, rgb(16 0 61) 107.31%);
  color: white;
  border-radius: 15px;
}


#connect-wallet:hover {
  background-color: rgba(19, 54, 98, 0.44);
  color: white !important;
}

#connect-wallet:active {
  background-color: #601cba;
}

#connect-wallet:focus {
  color: white !important;
} */

.arrow-bg {
  background: linear-gradient(225deg, #a839ff -45.77%, rgba(16, 0, 61, 0.5) 107.31%);
  border-radius: 24px;
  /* background: radial-gradient(131.8% 92.48% at 66.54% 17.86%, rgba(100, 57, 221, 0) 0%, #6439dd 100%); */
  border: 1px solid #a200fa;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-mainContainer {
  /* flex mx-auto text-center items-center mt-20 mb-4 */
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-items: center;
  margin-bottom: 0;
  color: rgba(255, 242, 209, 1);
  bottom: 0;
  /* width: 34%; */
  left: 0;
  right: 0;
  justify-content: center;
}

.footer-mainContainer, .body-wrapper:after {
  height: 36px;
  margin-top: 4rem;
}

.body-wrapper:after {
  content: "";
  display: block;
}

.footer-linkContainer {
  /* flex items-center gap-x-3 sm:ml-8 */
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  margin-left: 2rem;
}

.footer-copyright {
  /* text-xs hidden sm:block tracking-tight text-gray-100 */
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  letter-spacing: -0.025em;
  --tw-text-opacity: 1;
  /* color: rgba(243, 244, 246, var(--tw-text-opacity)); */
  color: inherit;
}

.footer-tombfinance {
  font-family: Amarante, cursive;
  display: block;
  color: #fff2d1;
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
  margin-right: 2rem /* 32px */;
  margin-bottom: 0.25rem /* 4px */;
}

@media (max-width: 640px) {
  .footer-tombfinance {
    display: none;
  }

  .footer-copyright {
    display: none;
  }
}

#connect-wallet .wallet-bg-long {
  background-color: none;
  background: transparent;
  background: url(assets/img/card-long.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -100;
}


#connect-wallet:hover .wallet-bg{
    background: url(/assets/img/card-hover.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -100;
}

.connectWallet-popup {
  min-width: 299px;
  min-height: 245px;
  background: linear-gradient(360deg, rgba(30, 0, 56, 1) 0%, rgba(22, 0, 44, 1) 56.05%);
  /* background: linear-gradient(360deg, rgba(30, 0, 56, 0.45) 0%, rgba(22, 0, 44, 0.6) 56.05%); */
  border: 1px solid #a000fa;
  border-radius: 20px;
}

.connectWallet-btn {
  min-height: 52px;
  width: 219px;
}

.connectWallet-btn#connect-METAMASK {
  border-color: #f3841e;
}

.connectWallet-btn#connect-WALLET_CONNECT {
  border-color: #66b9f9;
}

.connectWallet-btn:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.background {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1000;
  background: #16072b;
}

.cemetry {
  background: url(assets/img/cemetry.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 50vw;
  z-index: -100;
}

.sky {
  background: url(assets/img/sky.svg);
  background-repeat: no-repeat;
  background-size: 100vw;
}

.bg-stars {
  background: url(assets/img/bg-stars.svg);
  background-repeat: repeat-y;
  background-size: 100vw;
  z-index: -200;
}

.bg-stars-cemetery {
  background-repeat: repeat-y;
}

.background-home {
  position: absolute;
  height: 100%;
  width: 100vw;
  z-index: -300;
}

.logo-text {
  cursor: pointer;
  color: #fff2d1;
  font-family: Amarante, cursive;
  font-size: 1.875rem;
  line-height: 2.25rem;
  min-width: 180px;
}

.title {
  font-size: 3.75rem;
  line-height: 1;
  font-family: Amarante, cursive;
  letter-spacing: -0.05em;
  margin-bottom: 4rem;
  text-shadow: 0px -4px 12px rgba(255, 229, 171, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 1024px) {
  .menu-container3 {
    display: none !important;
  }
}

.menu-container3 {
  display: flex;
  margin-right: calc(1rem * var(0));
  letter-spacing: 0.025em;
  column-gap: 2rem;
  margin-top: 0.5rem;
  color: rgba(255, 242, 209, 1);
}

.tomb-text {
  color: rgba(255, 242, 209, 1);
}

.nav-selected {
  color: rgba(109, 255, 191) !important;
  text-shadow: 0px -2px 8px rgba(82, 194, 230, 0.5);
}

.textShadowLgSelected {
  filter: drop-shadow(0px -2px 8px rgba(82, 194, 230, 0.5));
}

a {
  color: inherit;
  text-decoration: inherit;
}

.menu-item-style {
  /* font-size: 0.75rem; */
  line-height: 1rem /* 16px */;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  /* color: rgba(255, 242, 209, 1); */
}

.hoverEffect:hover {
  color: rgba(255, 255, 255);
}

.hoverEffect a {
  text-decoration: none;
}

.mobile-menu-container {
  display: none;
}

.mobile-menu {
  top: 5rem;
  /* position: absolute;
  background: #1f1639; */
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
    background-color: rgba(17,24,39,var(--tw-bg-opacity));
}

.connectWallet-container2 {
  position: static;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  padding-right: 0px;
}

@media (min-width: 1024px) {
  .menu-container3 {
    margin-right: calc(2.5rem * var(0));
    margin-left: calc(2.5rem * calc(1 - var(0)));
  }
}

@media (min-width: 1280px) {
  .menu-item-style {
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
  }
}

@media (min-height: 650px) {
  .currency-search-container {
    min-height: 50vh;
  }
}

@media (max-width:768px) {

  .wallet-account-element {
    background:none;
    border: none
  }

  .wallet-account-element:focus {
    background:none;
    border: none
  }

  #web3-status-connected:active, #web3-status-connected:focus{
    box-shadow:none
  }

  #web3-status-connected .gear.button.btn{
    margin-top: -1rem;
  }
  

  .gear.button.btn {
    width: 52px;
    border-radius: 10px;
  }

  .gear.button.btn svg {
    margin-left: -3px;
  }

  .header-frame {
    background:none;
    background-color: none;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.connected.wallet-bg {
  background: url(assets/img/card-hover.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.wallet-button .wallet-bg {
  background: url(assets/img/card.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.wallet-button .wallet-bg {
  background: url(assets/img/card.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.wallet-button:hover .wallet-bg {
  background: url(assets/img/card-hover.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -100;
}

.wallet-button .wallet-bg-long {
  background: url(assets/img/card-long.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -100;
}

.wallet-button:hover .wallet-bg-long {
  background: url(assets/img/card-long-hover.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.background {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1000;
}

.cemetry {
  background: url(assets/img/cemetry.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 50vw;
  z-index: -100;
}

.sky {
  background: url(assets/img/sky.svg);
  background-repeat: no-repeat;
  background-size: 100vw;
}

.bg-stars {
  background: url(assets/img/bg-stars.svg);
  background-repeat: repeat-y;
  background-size: 100vw;
  z-index: -200;
}

.bg-stars-cemetery{
  background-repeat: repeat-y;
}

.background-home {
  position: absolute;
  height: 100%;
  width: 100vw;
  z-index: -300;
}

.home-grave {
  position: absolute;
  z-index: -100;
  width: 100vw;
  height: 150vh;
  background: url(assets/img/home-grave.png);
  background-position: 50% 50%;

  background-repeat: no-repeat;
  background-size: 100vw;
}

@media only screen and (max-width: 700px) {
  .home-grave {
    background-size: 340vw;
  }
}
@media only screen and (min-width: 700px, max-width: 900px) {
  .home-grave {
    background-size: 200vw;
  }
}

.home-sky {
  background: url(assets/img/home-sky.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 1000px;
}

@layer base {
  body {
    overflow-x: hidden;
    color: rgba(255, 242, 209, 1);
    background-color: #16072b;
    font-family: Poppins;
  }

  .content-wrapper {
    min-height: calc(100vh - 235px);
    padding-bottom: 1rem;
  }
}

@layer components {
  .btn {
    @apply bg-tombGradient rounded-2xl px-6 py-2 border text-base font-semibold border-bright-purple;
  }

  .btn-disabled {
    background: none;
    @apply rounded-2xl px-6 py-2 border text-base font-semibold bg-gray-400 opacity-70 cursor-not-allowed border-gray-500;
  }

  .tableRow1 {
    background: linear-gradient(360deg, rgba(30, 0, 56, 0.75) 0%, #16002c 56.05%);
  }

  .tableRow2 {
    background: linear-gradient(360deg, rgba(30, 0, 56, 0.75) 0%, #16002c 56.05%);
    mix-blend-mode: exclusion;
  }

  .gradient {
    background: linear-gradient(225deg, #f500fa -45.77%, #4100fa 107.31%);
    position: absolute;
    z-index: -10;
    filter: blur(1px);
    border-radius: 30px;
  }

  .inner {
    height: calc(100% - 2px);
    border-radius: 30px;
    margin: 1px;
  }

  .btn-connect {
    color: #fff2d1;
    text-shadow: 0px -4px 12px rgba(255, 229, 171, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: left;

    border: 1px solid transparent;

    background: linear-gradient(225deg, #a839ff -45.77%, rgba(16, 0, 61, 0.5) 107.31%) padding-box,
      linear-gradient(225deg, #f500fa -45.77%, #4100fa 107.31%) border-box;
  }

  .warningBg {
    background: linear-gradient(180deg, #7900da 0%, rgba(54, 39, 69, 0.5) 100%), #6439dd;
  }

  .warningTextBg {
    background: radial-gradient(58.6% 108.6% at 55.4% 39.12%, rgba(100, 57, 221, 0.3) 0%, rgba(100, 57, 221, 0) 100%);
  }

  .warningText {
    transform: none !important;
  }

  .btn-transparent {
    background: transparent;
    border: 1px solid #f500fa;
    border-radius: 12px;
  }

  .white-border {
    background: transparent;
    border: 1px solid #fff2d1;
    border-radius: 12px;
  }

  .nav-selected {
    @apply text-cyan;
    text-shadow: 0px -2px 8px rgba(82, 194, 230, 0.5);
  }

  .textShadow {
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
  }
  .textShadowLg {
    text-shadow: 0px -4px 12px rgba(255, 229, 171, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .textShadowLgSelected {
    filter: drop-shadow(0px -2px 8px rgba(82, 194, 230, 0.5));
  }
}

#web3-status-connected{
  background:none;
  border: none;
  padding-right: 0px;
}

/* #market-nav-link{
  font-size: 0.825rem;
  background: none;
  border: none;
  border-bottom: 3px solid rgba(148, 1, 251, 1);
  border-radius: 0px;
}

#limits-nav-link{
    font-size: 0.825rem;
  background: none;
  border: none;
  border-bottom: 3px solid rgba(148, 1, 251, 1);
  border-radius: 0px;
} */
  /* font-size: 0.825rem;
  border-radius: 0.5rem; */

@media only screen and (max-width: 425px) {
  .cemetry {
    background-size: 87.5vw;
  }
}

@layer utilities {
  /* @variants hover, focus {
    .filter-none {
      filter: none;
    }
    .filter-grayscale {
      filter: grayscale(100%);
    }
  } */
}